<template>
  <div class="help-area">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="hero-title has-text-centered">
        <h3 class="title is-2 pt-6 txt-white">
          Atención al cliente
        </h3>
      </div>
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <div class="column is-4 has-background-light sidebar-menu">
              <div class="sidebar">
                <button class="sidebar-btn selected">
                  <div class="sidebar-btn-label hide-mobile"><label>Contactos</label></div>
                </button>
                <button class="sidebar-btn">
                  <div class="sidebar-btn-label hide-mobile"><label>Envíos y devoluciones</label></div>
                </button>
                <button class="sidebar-btn">
                  <div class="sidebar-btn-label hide-mobile"><label>Pagos seguros</label></div>
                </button>
                <button class="sidebar-btn">
                  <div class="sidebar-btn-label hide-mobile"><label>Detalles del pedido</label></div>
                </button>
                <button class="sidebar-btn">
                  <div class="sidebar-btn-label hide-mobile"><label>Preguntas Frecuentes (FAQ)</label></div>
                </button>
              </div>
              <div class="sidebar-mobile-wrap">
                <button class="step-btn" @click="openStepWrap()">
                  <label>Contactos</label>
                  <span class="icon-chevron-down" v-if="!mobileStepOpen"></span>
                  <span class="icon-chevron-up" v-if="mobileStepOpen"></span>
                </button>
                <transition name="fade">
                  <div class="step-wrap" v-if="mobileStepOpen">
                    <router-link :to="'/customer-supports/contacts'" exact>Contactos</router-link>
                    <router-link :to="'/customer-supports/shipping-and-returns'" exact>Envíos y devoluciones</router-link>
                    <router-link :to="'/customer-supports/secure-payments'" exact>Pagos seguros</router-link>
                    <router-link :to="'/customer-supports/order-details'" exact>Detalles del pedido</router-link>
                    <router-link :to="'/customer-supports/faq'" exact>Preguntas Frecuentes (FAQ)</router-link>
                  </div>
                </transition>
              </div>
            </div>

            <div class="column is-8 right-column">
              <h3 class="title is-4 pb-4 txt-c-mobile">Contactos</h3>
              <v-collapse-group :onlyOneActive="false" class="collapse-wrap">
                <v-collapse-wrapper  v-on:onStatusChange="checkCollapse">
                    <div class="header" v-collapse-toggle data-id="1">
                        <div class="filter-icon">
                          <span id="question-close-1" class="icon-filters-close"></span>
                          <span id="question-open-1" class="icon-filters-open hidden"></span>
                        </div>
                        <p class="filter-title">¿Como puedo contactar con vosotros?</p>
                    </div>
                    <div class="my-content" v-collapse-content>
                        <p>
                          Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Sed posuere consectetur est at lobortis.
                        </p>
                        <button class="button is-primary is-outlined mt-1 mr-3">Enviar un mensaje</button>
                        <button class="button is-primary is-outlined mt-1 mr-3">Abrir el chat</button>
                    </div>
                </v-collapse-wrapper>
                <v-collapse-wrapper v-on:onStatusChange="checkCollapse">
                    <div class="header" v-collapse-toggle data-id="2">
                        <div class="filter-icon">
                          <span id="question-close-2" class="icon-filters-close"></span>
                          <span id="question-open-2" class="icon-filters-open hidden"></span>
                        </div>
                        <p class="filter-title">¿Que hago si tengo problemas con mi pedido?</p>
                    </div>
                    <div class="my-content" v-collapse-content>
                        <p>
                          Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Sed posuere consectetur est at lobortis.
                        </p>
                        <button class="button is-primary is-outlined mt-1 mr-3">Enviar un mensaje</button>
                        <button class="button is-primary is-outlined mt-1 mr-3">Abrir el chat</button>
                    </div>
                </v-collapse-wrapper>
                <v-collapse-wrapper v-on:onStatusChange="checkCollapse">
                    <div class="header" v-collapse-toggle data-id="3">
                        <div class="filter-icon">
                          <span id="question-close-3" class="icon-filters-close"></span>
                          <span id="question-open-3" class="icon-filters-open hidden"></span>
                        </div>
                        <p class="filter-title">¿Puedes contactarnos por teléfono?</p>
                    </div>
                    <div class="my-content" v-collapse-content>
                        <p>
                          Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Sed posuere consectetur est at lobortis.
                        </p>
                        <button class="button is-primary is-outlined mt-1 mr-3">Enviar un mensaje</button>
                        <button class="button is-primary is-outlined mt-1 mr-3">Abrir el chat</button>
                    </div>
                </v-collapse-wrapper>
                <v-collapse-wrapper v-on:onStatusChange="checkCollapse">
                    <div class="header" v-collapse-toggle data-id="4">
                        <div class="filter-icon">
                          <span id="question-close-4" class="icon-filters-close"></span>
                          <span id="question-open-4" class="icon-filters-open hidden"></span>
                        </div>
                        <p class="filter-title">No he recibido ningún mail de confirmación. ¿Quien tengo que contactar?</p>
                    </div>
                    <div class="my-content" v-collapse-content>
                        <p>
                          Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Sed posuere consectetur est at lobortis.
                        </p>
                        <button class="button is-primary is-outlined mt-1 mr-3">Enviar un mensaje</button>
                        <button class="button is-primary is-outlined mt-1 mr-3">Abrir el chat</button>
                    </div>
                </v-collapse-wrapper>
              </v-collapse-group>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import {
  AddClassById,
  RemoveClassById
} from '@/utils/utils.js'

export default {
  name: 'Help',
  components: {
    HeroImage,
    Footer
  },
  data () {
    return {
      mobileStepOpen: false
    }
  },
  methods: {
    openStepWrap () {
      this.mobileStepOpen = !this.mobileStepOpen
    },
    checkCollapse (vm) {
      const id = vm.vm.$slots.default[0].data.attrs['data-id']
      if (vm.status) {
        // is open
        AddClassById('question-close-' + id, 'hidden')
        RemoveClassById('question-open-' + id, 'hidden')
      } else {
        // is close
        RemoveClassById('question-close-' + id, 'hidden')
        AddClassById('question-open-' + id, 'hidden')
      }
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="scss">
.help-area{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -310px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .sidebar-menu{
          .sidebar-mobile-wrap{
            display: none;
          }
          .sidebar{
            padding-top: 78px;
            padding-bottom: 78px;
            padding-left: 31px;
            padding-right: 28px;
            .sidebar-btn{
              position: relative;
              width: 100%;
              height: 50px;
              border: 0px;
              margin-bottom: 14px;
              background-color: transparent;
              display: flex;
              justify-content: space-between;
              &.selected{
                // border-right: 2px solid #E3914F;
                .sidebar-btn-label{
                  label{
                    color: #E3914F;
                    }
                }
                &:after{
                  content: "";
                  display: block;
                  width: 2px;
                  height: 100%;
                  position: absolute;
                  top: 0px;
                  right: -40px;
                  background-color: #E3914F;
                }
              }
              .sidebar-btn-label{
                position: relative;
                height: 100%;
                width: calc(100% - 14px);
                text-align: left;
                label{
                  width: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  color: #57567C;
                  text-transform: uppercase;
                  font-weight: 600;
                }
              }
            }
          }
        }
        .right-column{
          padding: 80px;
          p{
            padding-bottom: 21px;
          }
          ol{
            padding-left: 21px;
            padding-bottom: 21px;
          }
          ul{
            padding-left: 14px;
            padding-bottom: 21px;
          }
          .collapse-wrap{
            width: 100%;
            // max-width: 329px;
            .vc-collapse{
              padding-bottom: 17px;
              .header{
                width: 100%;
                position: relative;
                .filter-title{
                  color: #57567C;
                  font-size: 16px;
                  font-weight: 600;
                  letter-spacing: 0;
                  line-height: 23px;
                  padding-left: 35px;
                }
                .filter-icon{
                  position: absolute;
                  top: 2px;
                  left: 0px;
                  color: #57567C;
                  letter-spacing: 0;
                  line-height: 23px;
                  font-size: 1.3rem;
                  .icon-filters-close {
                    display: inline-block;
                    &.hidden{
                      display: none;
                    }
                  }
                  .icon-filters-open {
                    display: inline-block;
                    &.hidden{
                      display: none;
                    }
                  }
                }
              }
              .v-collapse-content{
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .help-area{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .right-column{
            padding: 35px;
          }
          .sidebar-menu{
            width: 100%;
            .sidebar-mobile-wrap{
              position: relative;
              display: block;
              height: 66px;
              width: 100%;
              .step-btn{
                display: block;
                height: 100%;
                width: 100%;
                max-width: 320px;
                border: 0px;
                margin: 0 auto;
                background-color: transparent;
                color: #E3914F;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: bold;
                span{
                  position: absolute;
                  top: 50%;
                  right: 21px;
                  transform: translate(0%, -50%);
                  font-size: 1.5rem;
                }
              }
              .step-wrap{
                display: block;
                width: 100%;
                background-color: #F6F4EF;
                position: absolute;
                top: 100%;
                left: 0px;
                z-index: 99999;
                a{
                  position: relative;
                  display: block;
                  height: 66px;
                  width: 100%;
                  max-width: 320px;
                  margin: 0 auto;
                  text-transform: uppercase;
                  font-size: 16px;
                  font-weight: bold;
                  text-align: center;
                  line-height: 66px;
                  &:after{
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 0%;
                    height: 2px;
                    background-color: #E3914F;
                    transition: all .3s ease-out;
                  }
                  &.router-link-active{
                    &:after{
                      width: 50%;
                    }
                  }
                }
              }
            }
            .sidebar{
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
